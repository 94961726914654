// test5

<template>
  	<v-container fluid>
    <v-row>
        <v-col cols="3">
		<v-card class="grey darken-3 overflow-hidden">
			<v-app-bar
				absolute
				dark dense
				scroll-target="#playground"
				color="blue-grey darken-3"
				elevate-on-scroll
			>
				<v-toolbar-title></v-toolbar-title>
			</v-app-bar>
			<v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="600">
				<v-container fluid style="height: 1500px;padding-top: 70px;">
					<v-row>
						<v-col class="mt-1 py-0">
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-card>
        </v-col>
        <v-col cols="9" class="pl-0">
		<v-card class="grey darken-3">
			<v-app-bar absolute dark dense color="blue-grey darken-3">
				<v-toolbar-title>Formular</v-toolbar-title>
				<v-spacer/>
				<v-btn small class="ml-3" color="blue-grey">Config</v-btn>
			</v-app-bar>
			<v-sheet class="grey darken-3" height="600">
				<v-container fluid style="height: 500px;padding-top: 70px;">
					<v-row justify="start">
						<v-col cols="12">
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-card>
        </v-col>
    </v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'test5',
		components: {},
	  	props: {
	  	},
		data () {
			return {
			}
		},
		computed: {
		},
		mounted() {
		},
		methods: {
		},
		watch: {
        },
	}
</script>

<style>
</style>